<template>
    <v-app class="overflow-hidden">
        <!-- <v-app-bar app color="primary" dark>
            <div class="d-flex align-center">
                <v-img alt="Vuetify Logo" class="shrink mr-2" contain src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png" transition="scale-transition" width="40" />
    
                <v-img alt="Vuetify Name" class="shrink mt-1 hidden-sm-and-down" contain min-width="100" src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png" width="100" />
            </div>
    
            <v-spacer></v-spacer>
    
            <v-btn href="https://github.com/vuetifyjs/vuetify/releases/latest" target="_blank" text>
                <span class="mr-2">Latest Release</span>
                <v-icon>mdi-open-in-new</v-icon>
            </v-btn>
        </v-app-bar> -->
        <!-- {{ width }}:{{ height }} -->
        <v-layout fill-height="true">
            <v-container fluid fill-height="true">
                <v-row>
                    <v-col cols="6">
                        <CBR />
                    </v-col>
                    <v-col cols="6">
                        <InvestingCom/>
                        <XE />
                        <MultiCard />
                    </v-col>
                </v-row>
                <v-row fill-height="true">
                    <v-col cols="6"  fill-height="true">
                        <WorldTime/>
                    </v-col>
                    <v-col cols="6"  fill-height="true">
                        <GAR />
                    </v-col>
                </v-row>
            </v-container>
        </v-layout>
    </v-app>
</template>

<script>
import CBR from './components/Cbr';
import GAR from './components/Gar';
import XE from './components/Xe'
import MultiCard from './components/Multi'
import InvestingCom from './components/Investing';
import WorldTime from './components/Time';

export default {
    name: 'App',

    components: {
        CBR, InvestingCom, GAR, XE, MultiCard, WorldTime
    },

    data: () => ({
        browser: window.navigator.userAgent,
        height: window.screen.availHeight,
        width: window.screen.availWidth
    }),
};
</script>
