<template>
    <v-row>
        <v-col>
            <v-card style="height:418px;">
                <v-card-title class="d-flex justify-center">
                    <span class="text-body-2">КУРС ЦБ РФ</span>
                </v-card-title>
                <v-card-subtitle>{{ date }}</v-card-subtitle>
                <v-card-text>
                    <v-simple-table>
                        <tr>
                            <td>
                                1 USD
                            </td>
                            <td>
                                Американский доллар
                            </td>
                            <td class="text-right">
                                <span :class="getColor(UsdCur.Value - UsdCur.Previous)">{{ (UsdCur.Value -
                                    UsdCur.Previous).toFixed(2) }}</span>
                            </td>
                            <td class="text-right">
                                {{ UsdCur.Value }} руб
                            </td>
                        </tr>
                        <tr>
                            <td>
                                1 EUR
                            </td>
                            <td>
                                Евро
                            </td>
                            <td class="text-right">
                                <span :class="getColor(EurCur.Value - EurCur.Previous)">{{ (EurCur.Value -
                                    EurCur.Previous).toFixed(2) }}</span>
                            </td>
                            <td class="text-right">
                                {{ EurCur.Value }} руб
                            </td>
                        </tr>
                        <tr>
                            <td>
                                1 CNY
                            </td>
                            <td>
                                Китайский юань
                            </td>
                            <td class="text-right">
                                <span :class="getColor(CnyCur.Value - CnyCur.Previous)">{{ (CnyCur.Value -
                                    CnyCur.Previous).toFixed(2) }}</span>
                            </td>
                            <td class="text-right">
                                {{ CnyCur.Value }} руб
                            </td>
                        </tr>
                    </v-simple-table>
                    <v-row class="d-block text-center">
                        <img style="height:285px;width:100%;padding-top:20px;" :src="'data:image/png;base64, ' + graph">
                    </v-row>
                    <v-row>

                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import axios from 'axios';

// const today = moment();
// let last7Days = [];
// for (let i = 1; i >= 0; i--) { last7Days.push(today.clone().subtract(i, 'days').format('DD/MM/YYYY')); }
// console.log(last7Days);

// const CurCodes = {
//     USDCode: 'R01235',
//     EURCode: 'R01239',
//     CNYCode: 'R01375'
// }

const gradients = [
    ['#222'],
    ['#42b3f4'],
    ['red', 'orange', 'yellow'],
    ['purple', 'violet'],
    ['#00c6ff', '#F0F', '#FF0'],
    ['#f72047', '#ffd200', '#1feaea'],
]


export default {
    name: 'CBR',

    data: () => ({
        date: null,
        UsdCur: [],
        EurCur: [],
        CnyCur: [],
        width: 2,
        radius: 10,
        padding: 8,
        lineCap: 'round',
        gradient: gradients[5],
        value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
        gradientDirection: 'top',
        gradients,
        fill: false,
        type: 'trend',
        autoLineWidth: false,
        interval: null,
        graph: null,
        razm: {w: null, h: null}
    }),
    methods: {
        getColor(val) {
            if (val >= 0) {
                return 'green--text';
            } else {
                return 'red--text';
            }
        },
        updateCurrencies() {
            axios.get('https://www.cbr-xml-daily.ru/daily_json.js', { mode: 'no-cors' })
                .then(res => {
                    console.log(res)
                    this.date = res.data.Date;
                    this.UsdCur = res.data.Valute['USD'];
                    this.EurCur = res.data.Valute['EUR'];
                    this.CnyCur = res.data.Valute['CNY'];
                });
                axios.get('http://kutakbaev.ru:8080/graph')
                .then(res => {
                    console.log(res)
                    this.graph = res.data.graph;
                });
        }
    },
    mounted() {
        // eslint-disable-next-line
        self = this;
        this.$nextTick(function () {
            // eslint-disable-next-line
            self = this.updateCurrencies()
        })

    },
    created() {
        // eslint-disable-next-line
        let self = this;
        this.interval = setInterval(() => {
            self.updateCurrencies()
        },3600000);
        this.razm.w = window.innerWidth;
        this.razm.h = window.innerHeight;
    },
    beforeDestroy() {
        // prevent memory leak
        clearInterval(this.interval)
    },
}
</script>