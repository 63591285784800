<template>
    <v-row>
        <v-col>
            <v-card style="height:135px;">
                <v-card-title class="d-flex justify-center">
                    <span class="text-body-2">XE</span>
                </v-card-title>
                <v-card-text>
                    <v-simple-table>
                        <tr>
                            <td>
                                1 USD
                            </td>
                            <td>
                                Американский доллар
                            </td>
                            <td>

                            </td>
                            <td  class="text-right">
                                {{ usdrub }} ₽
                            </td>
                        </tr>
                        <tr>
                            <td>
                                1 CNY
                            </td>
                            <td>
                                Китайский юань
                            </td>
                            <td>

                            </td>
                            <td  class="text-right">
                                {{ cnyusd }} $
                            </td>
                        </tr>
                        <tr>
                            <td>
                                1 USD
                            </td>
                            <td>
                                Американский доллар
                            </td>
                            <td>

                            </td>
                            <td  class="text-right">
                                {{ usdeur }} €
                            </td>
                        </tr>
                        <tr>
                            <td>
                                1 EUR
                            </td>
                            <td>
                                Евро
                            </td>
                            <td>

                            </td>
                            <td  class="text-right">
                                {{ eurusd }} $
                            </td>
                        </tr>
                    </v-simple-table>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import axios from 'axios'
export default {
    name: 'XE',

    data: () => ({
        usdrub: null,
        cnyusd: null,
        usdeur: null,
        eurusd: null,
        interval: null,
    }),
    mounted(){
        let self = this;
        this.interval = setInterval(() => {
            axios.get('http://kutakbaev.ru:8080/xe').then(res => {
                self.usdrub = res.data.usdrub;
                self.cnyusd = res.data.cnyusd;
                self.eurusd = res.data.eurusd;
                self.usdeur = res.data.usdeur;
            })
        }, 60000);
    },
    beforeDestroy() {
        // prevent memory leak
        clearInterval(this.interval)
    },
}
</script>