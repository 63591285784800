<template>
    <v-row class="fill-height">
        <v-col class="fill-height">
            <v-card style="height:80px;">
                <v-card-text class="fill-height">
                    <v-row class="align-center fill-height">
                        <v-col class="text-center">
                            <span class="text-h6">ТУРЦИЯ</span><br>
                            <svg width="18" height="18" viewBox="0 0 52 52" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <circle cx="26" cy="26" r="26" fill="#24E866" />
                                <rect x="22" y="14" width="4" height="18" fill="#262626" />
                                <rect x="40" y="28" width="4" height="18" transform="rotate(90 40 28)" fill="#262626" />
                            </svg>
                            <span class="text-h6"> {{ turkey }}</span>
                        </v-col>
                        <v-col class="text-center">
                            <span class="text-h6">ИНДОНЕЗИЯ</span><br>
                            <svg width="18" height="18" viewBox="0 0 52 52" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <circle cx="26" cy="26" r="26" fill="#24E866" />
                                <rect x="22" y="14" width="4" height="18" fill="#262626" />
                                <rect x="40" y="28" width="4" height="18" transform="rotate(90 40 28)" fill="#262626" />
                            </svg>
                            <span class="text-h6"> {{ indoneziya }}</span>
                        </v-col>
                        <v-col class="text-center">
                            <span class="text-h6">ГОНКОНГ</span><br>
                            <svg width="18" height="18" viewBox="0 0 52 52" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <circle cx="26" cy="26" r="26" fill="#24E866" />
                                <rect x="22" y="14" width="4" height="18" fill="#262626" />
                                <rect x="40" y="28" width="4" height="18" transform="rotate(90 40 28)" fill="#262626" />
                            </svg>
                            <span class="text-h6"> {{ gonkong }}</span>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
//import moment from 'moment';
import moment from 'moment-timezone'

export default {
    name: 'WorldTime',

    data: () => ({
        gonkong: null,
        turkey: null,
        indoneziya: null,
        interval: null,
    }),
    beforeDestroy() {
        // prevent memory leak
        clearInterval(this.interval)
    },
    created() {
        console.log(this)
 
        this.interval = setInterval(() => {
            // Concise way to format time according to system locale.
            // In my case this returns "3:48:00 am"
            this.gonkong = moment().tz('Asia/Hong_Kong').format('H:mm')
            this.turkey = moment().format('H:mm')
            this.indoneziya = moment().tz('Asia/Jakarta').format('H:mm')
        }, 1000)
    },
}
</script>