<template>
    <v-row>
        <v-col>
            <v-card style="height:80px;">
                <v-card-title class="d-flex justify-center">
                    <span class="text-body-2">GAR</span>
                </v-card-title>
                <v-card-text>
                    <v-simple-table>
                        <tr>
                            <td>
                                1 USDT
                            </td>
                            <td>
                                Американский доллар
                            </td>
                            <td>

                            </td>
                            <td  class="text-right">
                                {{ usdtrub }} руб
                            </td>
                        </tr>
                    </v-simple-table>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'GAR',

    data: () => ({
        usdtrub: null,
        interval: null,
    }),
    created() {
        // eslint-disable-next-line
        let self = this;
        this.interval = setInterval(() => {
            console.log("Starting connection to WebSocket Server")
            this.connection = new WebSocket("wss://ws.garantex.org/?stream=deal&stream=chat&stream=deal_payment_details&stream=btcrub")

            this.connection.onmessage = function (event) {
                //console.log(self)
                //console.log(event.data);
                if (JSON.parse(event.data)['btcrub.update']) {
                    self.usdtrub = JSON.parse(event.data)['btcrub.update']['exchangers']['usdtrub'].price.usdtrub
                }
            }
            this.connection.onclose = function (event) {
                console.log(event);
            }

            this.connection.onopen = function (event) {
                console.log(event)
                console.log("Successfully connected to the echo websocket server...")
            }
        }, 60000);
    },
    beforeDestroy() {
        // prevent memory leak
        clearInterval(this.interval)
    },
}
</script>